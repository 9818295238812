// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-container {
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2:hover {
  text-decoration: underline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.zoom {
  border-radius: 5px;
  transition: transform 0.2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 700px) {
  .projects-container {
    display: block;
    padding-left: 5%;
    padding-right: 5%;
  }
  .projects-container .projects-grid {
    display: block;
  }
  .projects-container .projects-grid .project {
    padding-bottom: 5%;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Project.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,qCAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,0BAAA;EACA,uGAAA;AACJ;;AAEA;EACI,kBAAA;EACA,0BAAA;EACA,cAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAGI;EACI;IACI,cAAA;IACA,gBAAA;IACA,iBAAA;EAAV;EAGM;IACI,cAAA;EADV;EAIM;IACI,kBAAA;EAFV;AACF","sourcesContent":[".projects-container {\n    display: flex;\n    flex-direction: column;\n    padding: 5% 10%;\n    text-align: left;\n}\n  \n.projects-container .projects-grid {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-gap: 50px;\n}\n  \n.projects-container .projects-grid .project {\n    text-align: left;\n}\n  \n.project h2:hover {\n    text-decoration: underline;\n    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;\n}\n  \n.zoom {\n    border-radius: 5px;\n    transition: transform .2s;\n    margin: 0 auto;\n}\n  \n.zoom:hover {\n    transform: scale(1.05);\n}\n\n@media screen {\n    @media (max-width: 700px) {\n        .projects-container {\n            display: block;\n            padding-left: 5%;\n            padding-right: 5%;\n        }\n  \n        .projects-container .projects-grid {\n            display: block;\n        }\n      \n        .projects-container .projects-grid .project {\n            padding-bottom: 5%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
