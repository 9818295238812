import React from 'react';
import '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';
import { faPeopleRoof } from '@fortawesome/free-solid-svg-icons';

const labelsFirst = [
  'React',
  'Next',
  'TypeScript',
  'JavaScript',
  'HTML5',
  'CSS3',
  'SASS',
  'SQL',
  'MySQL',
  'AWS',
  'Azure',
  'Postman',
];

const labelsSecond = [
  'GitHub',
  'Microsoft 365',
  'Jira',
  'Teams',
  'Google Workspace',
];


function Expertise() {
  return (
    <div className="container" id="expertise">
      <div className="skills-container">
        <h1>Expertise</h1>
        <div className="skills-grid">
          <div className="skill">
            <FontAwesomeIcon icon={faReact} size="3x" />
            <h3>Full Stack Web Development</h3>
            <p>
              I have built a diverse array of web applications using modern
              technologies such as React and Node, with strong proficiency in
              both frontend and backend development.
            </p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {labelsFirst.map((label, index) => (
                <Chip key={index} className="chip" label={label} />
              ))}
            </div>
          </div>
          <div className="skill">
            <FontAwesomeIcon icon={faPeopleRoof} size="3x" />
            <h3>Management</h3>
            <p>
              I have successfully led
              teams, managed multi-branch operations, developed training
              programs, resolved customer issues, handled inventory and sales
              reporting, and ensured operational efficiency across various
              roles, including as a Regional Manager and Retail Sales
              Supervisor.
            </p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {labelsSecond.map((label, index) => (
                <Chip key={index} className="chip" label={label} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expertise;
