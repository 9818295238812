import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Career History</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="2021.11 - 2024.01"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Frontend Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Wantreez Music Inc<br />Gangnam District, Seoul, South Korea</h4>
            <p>
              Full-stack Web Development, Project Management, Organized internship
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019.08 - 2021.02"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Retail Sales Supervisor</h3>
            <h4 className="vertical-timeline-element-subtitle">Westmount Shell<br />Swift Current, Saskatchewan, Canada</h4>
            <p>
            Supervised and coordinated
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019.08 - 2021.02"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Retail Sales Clerk</h3>
            <h4 className="vertical-timeline-element-subtitle">Westmount Shell<br />Swift Current, Saskatchewan, Canada</h4>
            <p>
            Processed sales goods and services in store
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016.12 - 2018.12"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Regional Manager</h3>
            <h4 className="vertical-timeline-element-subtitle">Smart Management Aus Pty Ltd<br />Gold Coast, Queensland, Australia</h4>
            <p>
              Managed 5 rental car branches in Gold Coast area of Queensland, Australia
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016.12 - 2018.12"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Supervisor</h3>
            <h4 className="vertical-timeline-element-subtitle">Smart Management Aus Pty Ltd<br />Gold Coast, Queensland, Australia</h4>
            <p>
              Directed detailer tasks based on the schedule
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016.04 - 2016.10"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Detailer</h3>
            <h4 className="vertical-timeline-element-subtitle">Smart Management Aus Pty Ltd<br />Gold Coast, Queensland, Australia</h4>
            <p>
              Conducted interior and exterior cleaning of vehicles
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;