// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
footer svg {
  width: 1.1em !important;
  height: 1.1em !important;
  margin-right: 10px;
}
footer p {
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Footer.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;AACI;EACI,uBAAA;EACA,wBAAA;EACA,kBAAA;AACR;AAEI;EACI,gBAAA;AAAR","sourcesContent":["footer {\n    text-align: center;\n    padding-top: 50px;\n    padding-bottom: 50px;\n\n    svg {\n        width: 1.1em !important;\n        height: 1.1em !important;\n        margin-right: 10px;\n    }\n\n    p {\n        font-size: 0.8em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
