// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-timeline span {
  color: white;
  font-family: "Courier Prime", monospace;
}

.vertical-timeline p {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element p {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element h3 {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element h4 {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element span {
  color: rgb(39, 40, 34);
}

.vertical-timeline-element .vertical-timeline-element-date {
  color: white;
}

.svg-inline--fa {
  color: white;
}

@media screen and (max-width: 1170px) {
  .vertical-timeline-element .vertical-timeline-element-date {
    color: rgb(39, 40, 34);
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Timeline.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,uCAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI;IACI,sBAAA;EACN;AACF","sourcesContent":[".vertical-timeline span {\n    color: white;\n    font-family: 'Courier Prime', monospace;\n}\n\n.vertical-timeline p {\n    color: rgb(39, 40, 34);\n}\n\n.vertical-timeline-element p {\n    color: rgb(39, 40, 34);\n}\n\n.vertical-timeline-element h3 {\n    color: rgb(39, 40, 34);\n}\n\n.vertical-timeline-element h4 {\n    color: rgb(39, 40, 34);\n}\n\n.vertical-timeline-element span {\n    color: rgb(39, 40, 34);\n}\n\n.vertical-timeline-element .vertical-timeline-element-date {\n    color: white;\n}\n\n.svg-inline--fa {\n    color: white;\n}\n\n@media screen and (max-width: 1170px) {\n    .vertical-timeline-element .vertical-timeline-element-date {\n        color: rgb(39, 40, 34);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
