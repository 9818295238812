// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_wrapper svg {
  font-size: 1em;
  vertical-align: middle;
  margin-right: 5px;
}

.contact-form {
  padding-top: 20px;
}
.contact-form button {
  float: right;
  background-color: white;
  color: #050f0b;
}
.contact-form button:hover {
  background-color: #5000ca;
  color: white;
}
.contact-form fieldset {
  background-color: white;
}

label {
  font-size: 1.2rem;
  font-family: "DomaineDispNar-Medium", sans-serif;
}

.form-flex {
  display: flex;
  gap: 15px;
  padding-bottom: 15px;
}

.form-flex .MuiFormControl-root {
  width: 100%;
}

form {
  width: 100% !important;
}

.body-form {
  width: 100% !important;
  margin-bottom: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Contact.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;AACE;EACE,YAAA;EACA,uBAAA;EACA,cAAA;AACJ;AAEE;EACE,yBAAA;EACA,YAAA;AAAJ;AAGE;EACE,uBAAA;AADJ;;AAKA;EACE,iBAAA;EACA,gDAAA;AAFF;;AAKA;EACE,aAAA;EACA,SAAA;EACA,oBAAA;AAFF;;AAKA;EACE,WAAA;AAFF;;AAKA;EACE,sBAAA;AAFF;;AAKA;EACE,sBAAA;EACA,8BAAA;AAFF","sourcesContent":[".contact_wrapper svg {\n  font-size: 1em;\n  vertical-align: middle;\n  margin-right: 5px;\n}\n\n.contact-form {\n  padding-top: 20px;\n\n  button {\n    float: right;\n    background-color: white;\n    color: #050f0b;\n  }\n\n  button:hover {\n    background-color: #5000ca;\n    color: white;\n  }\n\n  fieldset {\n    background-color: white;\n  }\n}\n\nlabel {\n  font-size: 1.2rem;\n  font-family: 'DomaineDispNar-Medium', sans-serif;\n}\n\n.form-flex {\n  display: flex;\n  gap: 15px;\n  padding-bottom: 15px;\n}\n\n.form-flex .MuiFormControl-root {\n  width: 100%;\n}\n\nform {\n  width: 100% !important;\n}\n\n.body-form {\n  width: 100% !important;\n  margin-bottom: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
