import React from "react";
import rd from "../assets/images/rd.gif"
import phm from "../assets/images/phm.gif"
import rdhome from "../assets/images/rhymeduck home.png"
import helloipju from "../assets/images/helloipju.png"
import gyeonggi from "../assets/images/gyeonggi.jpg"
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>Projects</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://www.rhymeduck.com/" target="_blank" rel="noreferrer"><img src={rdhome} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.rhymeduck.com/" target="_blank" rel="noreferrer"><h2>Rhymeduck Home Page</h2></a>
                <p>Renewal and feature integration of the main service page.</p>
            </div>
            <div className="project">
                <a href="https://player.rhymeduck.com/" target="_blank" rel="noreferrer"><img src={rd} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://player.rhymeduck.com/" target="_blank" rel="noreferrer"><h2>Rhymeduck Web Player</h2></a>
                <p>Developed in-house streaming player for domestic.</p>
            </div>
            <div className="project">
                <a href="https://player.playhotelmusic.com/" target="_blank" rel="noreferrer"><img src={phm} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://player.playhotelmusic.com/" target="_blank" rel="noreferrer"><h2>PHM Web Player</h2></a>
                <p>Developed in-house streaming player for international.</p>
            </div>
            <div className="project">
                <a href="https://www.helloipju.com/" target="_blank" rel="noreferrer"><img src={helloipju} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://www.helloipju.com/" target="_blank" rel="noreferrer"><h2>Helloipju</h2></a>
                <p>Developed commercial platform named as 'Helloipju'.</p>
            </div>
            <div className="project">
                <img src={gyeonggi} className="zoom" alt="thumbnail" width="100%"/>
                <h2>The 5th Gyeonggi Advertising and Public Relations Festival</h2>
                <p>Managed 'The 5th Gyeonggi Advertising and Public Relations Festival' projects for government initiatives.</p>
            </div>
        </div>
    </div>
    );
}

export default Project;